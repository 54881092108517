import React from "react"

import SEO from "../components/seo"
import Form from "../components/form"

import Logo from "../assets/static/logo.png"
import Heart from "../assets/static/heart.png"
import Mark from "../assets/static/mark.png"
import Engagement from "../assets/static/engagement.png"
import Handshake from "../assets/static/handshake.png"
import Hat from "../assets/static/TUTEURALHEURE_LOGORS_TUTEUR_LOGOCHAPEAU_BLEU_POURFONDCLAIR.png"

import "../assets/css/A.post-25.css,qx68393.pagespeed.cf.JaKSKwofFr.css"
import "../assets/css/frontend.min.css,qx68393.pagespeed.ce.EjDU7T1mTD.css"
import "../assets/css/all.css"

const IndexPage = () => (
  <>
    <SEO title="Tuteur à l'Heure " />
    <div
      data-server-rendered="true"
      id="app"
      className="layout landing no-progressbar step-step-cible auto-continue"
    >
      <div className="steps-zone">
        <div className="steps-content">
          <div className="secondary-zone">
            <div className="secondary-zone-content">
              <div className="secondary-header">
                <a href="https://www.tuteuralheure.fr/">
                  <img
                    src={Logo}
                    alt="Cours accompagnement scolaire Tuteur à l'heure"
                    width={510}
                    height={258}
                  />
                </a>
                {/**/}
                <div className="primary-header hidden-lg">
                  <div className="hidden-xs">
                    <div className="base-block-type-wysiwyg">
                      <p></p>
                      <div className="contact-us">
                        <svg
                          width={64}
                          height={64}
                          viewBox="0 0 64 64"
                          xmlns="http://www.w3.org/2000/svg"
                          className="base-icon icon-call"
                        >
                          <path
                            d="M41.466 38.233c1.306-.69 2.685-.844 3.654-.253.862.527 8.94 5.888 9.819 6.503 1.261.884 1.44 2.91.309 4.523-2.208 3.16-5.943 7.054-7.903 6.993-.96-.03-1.617-.092-2.639-.304-1.48-.308-3.117-.859-4.947-1.719-4.82-2.263-10.42-6.392-16.883-12.857-6.462-6.463-10.59-12.063-12.853-16.883-.86-1.83-1.41-3.469-1.718-4.949a13.62 13.62 0 01-.304-2.64c-.063-1.959 3.824-5.692 6.991-7.906 1.607-1.12 3.626-.93 4.516.3.495.686 5.783 8.688 6.499 9.818.612.964.454 2.348-.243 3.667-.139.266-.967 1.74-2.484 4.425.48 2.033 2.387 4.716 5.72 8.049 3.333 3.333 6.015 5.239 8.044 5.716a426.481 426.481 0 014.422-2.483z"
                            fill="currentColor"
                            fillRule="evenodd"
                          />
                        </svg>
                        <div>
                          <div className="number">
                            <a href="tel:07 80 91 91 20">07 80 91 91 20</a>
                          </div>
                          <div className="label">
                            Disponibles 7j/7j de 10h à 18h
                          </div>
                        </div>
                      </div>
                      <p />
                    </div>
                  </div>
                </div>
              </div>
              <div className="secondary-body">
                <div>
                  <div className="base-block-type-wysiwyg hidden-xs">
                    <div className="image-zone">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/qyXjCvTKQNw"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <h1>
                      Nos tuteurs se déplacent dans tout Paris
                    </h1>
                  </div>
                  <div className="base-block-type-wysiwyg hidden-lg">
                    <h1>PROFITEZ D’UN ACCOMPAGNEMENT SCOLAIRE SUR MESURE </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Form />
        </div>
        <div className="after-bottom-blocks">
          <div className="padded-content">
            <div className="block-type-product-wide row-reverse">
              <div className="text-zone">
                <p>
                  Pour accompagner chacun de nos élèves vers la réussite
                  scolaire, nous proposons deux services ciblés:
                </p>
                <ul>
                  <li>
                    <br></br>
                    &#x025FC; Des&nbsp;
                    <span style={{ color: "#3C9BEC" }}>
                      <strong>cours particuliers</strong>
                    </span>
                    &nbsp;avec l’un de nos tuteurs pédagogues, sélectionnez
                    spécifiquement en fonction des difficultés de l’élève et de
                    ses passions pour renforcer le lien pédagogique.
                  </li>
                  <li>
                    <br></br>
                    &#x025FC; Des&nbsp;
                    <span style={{ color: "#3C9BEC" }}>
                      <strong>cours en ligne</strong>
                    </span>
                    &nbsp;lorsque les cours à domicile ne sont pas possibles,
                    avec une plateforme adaptée pour maximiser les résultats du
                    cours.
                  </li>
                  <li>
                    <br></br>
                    &#x025FC; Des&nbsp;
                    <span style={{ color: "#3C9BEC" }}>
                      <strong>stages de révisions</strong>
                    </span>
                    &nbsp;pour les examens de 2021 (brevet et baccalauréat).
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-blocks" style={{ backgroundColor: "#3C9BEC" }}>
          <div className="padded-content">
            <div>
              <div className="base-block-type-wysiwyg">
                <p style={{ textAlign: "center" }}>
                  <h2>Nos atouts&nbsp;?</h2>
                </p>
              </div>
            </div>
            <div className="flex-wrapper">
              <div className="base-block-type-wysiwyg">
                <p style={{ textAlign: "center" }}>
                  <img
                    src={Heart}
                    alt="Tuteurs pédagogues et passionnés"
                    width={64}
                    height={64}
                  />
                </p>
                <h6 style={{ textAlign: "center" }}>
                  Des tuteurs pédagogues <br></br>et passionnés
                </h6>
                <p style={{ textAlign: "center" }}>
                  Plus de 250 tuteurs prêts à venir&nbsp;
                  <br />
                  vous aider à domicile et en ligne,
                  <br />
                  pour préparer le second trimestre{" "}
                </p>
              </div>
              <div className="base-block-type-wysiwyg">
                <p style={{ textAlign: "center" }}>
                  <img
                    src={Engagement}
                    alt="Aucun engagement"
                    width={64}
                    height={64}
                  />
                </p>
                <h6 style={{ textAlign: "center" }}>Aucun engagement&nbsp;</h6>
                <p style={{ textAlign: "center" }}>
                  Vous ne payez que les cours.
                  <br />
                  Si un tuteur ne vous convient pas,
                  <br />
                  nous le remplaçons
                </p>
              </div>
              <div className="base-block-type-wysiwyg">
                <p style={{ textAlign: "center" }}>
                  <img src={Mark} alt="Élèves heureux" width={64} height={64} />
                </p>
                <h6 style={{ textAlign: "center" }}>96% d’élèves heureux</h6>
                <p style={{ textAlign: "center" }}>
                  Notre équipe est présente 6j/7j <br />
                  pour prendre le temps d’écouter et <br /> de comprendre les
                  difficultés que
                  <br /> vous traversez. Nous trouverons une <br />
                  solution ensemble, <br />
                  vous pouvez compter sur nous
                </p>
              </div>
              <div className="base-block-type-wysiwyg">
                <p style={{ textAlign: "center" }}>
                  <img
                    src={Handshake}
                    alt="Mise en relation"
                    width={64}
                    height={64}
                  />
                </p>
                <h6 style={{ textAlign: "center" }}>
                  Une mise en relation <br></br>très personnelle
                </h6>
                <p style={{ textAlign: "center" }}>
                  Nous vous poserons plusieurs questions <br /> afin de trouver
                  le tuteur qui saura
                  <br /> solutionner vos problèmes. <br />
                  Nous ne vous mettons en relation
                  <br /> qu’une fois le profil parfait à disposition
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="after-bottom-blocks">
        <div className="padded-content">
          <div className=" block-group">
            <div className="base-block-type-wysiwyg">
              <div
                className="elementor-element elementor-element-94ff581 heading-title elementor-widget elementor-widget-heading"
                ata-id="94ff581"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <h2
                  className="elementor-heading-title elementor-size-xl"
                  style={{ color: "#3C9BEC", textAlign: "center" }}
                >
                  TUTEUR À L'HEURE EN UNE MINUTE
                </h2>{" "}
              </div>
            </div>
            <div className="block-type-product-wide row-reverse">
              <div className="image-zone">
                <iframe
                  title="pres-tuteur"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/3KEqaU2GlZs"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="text-zone">
                <h2>Cours particuliers</h2>
                <p>
                  Entre étudiants, on se comprend. Des cours particuliers avec
                  des étudiants pédagogues, patients et d'un excellent niveau :
                  des supers tuteurs en mesure d'aider chaque élève en
                  difficulté avec méthodologie, et les accompagner à se
                  reprendre en main
                </p>
              </div>
            </div>
          </div>
          <div className="elementor elementor-25 ">
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-10a1c67 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="10a1c67"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-row">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-efc081e"
                    data-id="efc081e"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-76a8831 elementor-widget elementor-widget-spacer"
                          data-id="76a8831"
                          data-element_type="widget"
                          id="nos-prestations"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-94ff581 heading-title elementor-widget elementor-widget-heading"
                          data-id="94ff581"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-xl">
                              Nos prestations
                            </h2>{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-0b862dd elementor-widget elementor-widget-spacer"
                          data-id="0b862dd"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-480c992 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="480c992"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-row">
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-068264f"
                    data-id="068264f"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-49d9280 elementor-widget elementor-widget-spacer"
                          data-id="49d9280"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-e530eb5 elementor-view-default elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                          data-id="e530eb5"
                          data-element_type="widget"
                          data-widget_type="icon-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-icon-box-wrapper">
                              <div className="elementor-icon-box-icon">
                                <span className="elementor-icon elementor-animation-">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    id="Capa_1"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 512 512"
                                    style={{
                                      enableBackground: "new 0 0 512 512",
                                    }}
                                    xmlSpace="preserve"
                                    width={212}
                                    height={212}
                                  >
                                    <g>
                                      <polygon
                                        style={{ fill: "#3C9BEC" }}
                                        points="474.5,7.5 256,256 474.5,504.5 504.5,504.5 504.5,7.5 "
                                        data-original="#54BBFF"
                                        data-old_color="#54BBFF"
                                      />
                                      <rect
                                        x="7.5"
                                        y="7.5"
                                        style={{ fill: "#3C9BEC" }}
                                        width={467}
                                        height={497}
                                        data-original="#8DD2FF"
                                        data-old_color="#8DD2FF"
                                      />
                                      <polygon
                                        style={{ fill: "#C6E8FF" }}
                                        points="444.5,37.5 256,256 444.5,474.5 474.5,474.5 474.5,37.5 "
                                        data-original="#C6E8FF"
                                      />
                                      <rect
                                        x="37.5"
                                        y="37.5"
                                        style={{ fill: "#E6F0FF" }}
                                        width={407}
                                        height={437}
                                        data-original="#DEF1FF"
                                        data-old_color="#DEF1FF"
                                      />
                                      <path
                                        style={{ fill: "#3C9BEC" }}
                                        d="M272.37,121.543c-2.094-0.703-4.241-1.043-6.367-1.043c-6.143,0-12.091,2.837-15.944,7.924  l-49.999,66c-6.67,8.805-4.94,21.349,3.865,28.019c3.613,2.737,7.854,4.06,12.062,4.059c6.047,0,12.024-2.732,15.957-7.924  L246,200.022V371.5c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V140.501C286,131.911,280.514,124.279,272.37,121.543z"
                                        data-original="#54BBFF"
                                        data-old_color="#54BBFF"
                                      />
                                      <path
                                        d="M504.5,0H7.5C3.358,0,0,3.358,0,7.5v497c0,4.142,3.358,7.5,7.5,7.5h497c4.142,0,7.5-3.358,7.5-7.5V7.5  C512,3.358,508.642,0,504.5,0z M497,497H15V15h482V497z"
                                        data-original="#000000"
                                        className="active-path"
                                        style={{ fill: "#4A25AA" }}
                                        data-old_color="#000000"
                                      />
                                      <path
                                        d="M37.5,482h437c4.142,0,7.5-3.358,7.5-7.5v-437c0-4.142-3.358-7.5-7.5-7.5h-437c-4.142,0-7.5,3.358-7.5,7.5v45  c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5V45h422v422H45V112.5c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v362  C30,478.642,33.358,482,37.5,482z"
                                        data-original="#000000"
                                        className="active-path"
                                        style={{ fill: "#4A25AA" }}
                                        data-old_color="#000000"
                                      />
                                      <path
                                        d="M266,399c15.164,0,27.5-12.336,27.5-27.5V140.501c0-11.825-7.531-22.301-18.742-26.068  c-2.832-0.951-5.777-1.433-8.755-1.433c-8.56,0-16.755,4.073-21.922,10.896l-50,66c-9.156,12.087-6.772,29.37,5.314,38.526  c4.82,3.651,10.556,5.581,16.588,5.581c8.698,0,16.693-3.972,21.938-10.895l0.579-0.764V371.5C238.5,386.664,250.836,399,266,399z   M225.965,214.049c-2.384,3.147-6.022,4.953-9.98,4.953c-2.736,0-5.34-0.877-7.532-2.537c-5.494-4.162-6.578-12.018-2.416-17.512  l50-66c2.349-3.101,6.075-4.953,9.966-4.953c1.351,0,2.69,0.22,3.979,0.652c5.095,1.712,8.519,6.474,8.519,11.849V371.5  c0,6.893-5.607,12.5-12.5,12.5s-12.5-5.607-12.5-12.5V200.022c0-3.222-2.058-6.083-5.111-7.109c-0.785-0.264-1.59-0.391-2.388-0.391  c-2.303,0-4.534,1.064-5.979,2.972L225.965,214.049z"
                                        data-original="#000000"
                                        className="active-path"
                                        style={{ fill: "#4A25AA" }}
                                        data-old_color="#000000"
                                      />
                                    </g>{" "}
                                  </svg>{" "}
                                </span>
                              </div>
                              <div className="elementor-icon-box-content">
                                <h3 className="elementor-icon-box-title">
                                  <span>COURS À DOMICILE</span>
                                </h3>
                                <p className="elementor-icon-box-description">
                                  Des cours particuliers directement au domicile
                                  de nos familles. Le tuteur sélectionné pour
                                  dispenser les cours se situe dans un{" "}
                                  <strong style={{ color: "#4A25AA" }}>
                                    {" "}
                                    rayon de 30 minutes maximum{" "}
                                  </strong>{" "}
                                  du domicile de son élève. Cela garantit un
                                  soutien régulier, une grande disponibilité et
                                  de la proximité.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-2a11955 elementor-align-center elementor-widget elementor-widget-button"
                          data-id="2a11955"
                          data-element_type="widget"
                          data-widget_type="button.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-button-wrapper">
                              <a
                                href="https://www.tuteuralheure.fr/cours-a-domicile/"
                                className="elementor-button-link elementor-button elementor-size-md"
                                role="button"
                              >
                                <span className="elementor-button-content-wrapper">
                                  <span className="elementor-button-text">
                                    EN SAVOIR PLUS
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-4534051"
                    data-id={4534051}
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-33eeb4b elementor-widget elementor-widget-spacer"
                          data-id="33eeb4b"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-236d60a elementor-view-default elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                          data-id="236d60a"
                          data-element_type="widget"
                          data-widget_type="icon-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-icon-box-wrapper">
                              <div className="elementor-icon-box-icon">
                                <span className="elementor-icon elementor-animation-">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    id="Capa_1"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 512 512"
                                    style={{
                                      enableBackground: "new 0 0 512 512",
                                    }}
                                    xmlSpace="preserve"
                                    width={212}
                                    height={212}
                                  >
                                    <g>
                                      <polygon
                                        style={{ fill: "#3C9BEC" }}
                                        points="474.5,7.5 256,256 474.5,504.5 504.5,504.5 504.5,7.5 "
                                        data-original="#54BBFF"
                                        data-old_color="#54BBFF"
                                      />
                                      <rect
                                        x="7.5"
                                        y="7.5"
                                        style={{ fill: "#3C9BEC" }}
                                        width={467}
                                        height={497}
                                        data-original="#8DD2FF"
                                        data-old_color="#8DD2FF"
                                      />
                                      <polygon
                                        style={{ fill: "#E6F0FF" }}
                                        points="444.5,37.5 256,256 444.5,474.5 474.5,474.5 474.5,37.5 "
                                        data-original="#C6E8FF"
                                        className="active-path"
                                        data-old_color="#C6E8FF"
                                      />
                                      <rect
                                        x="37.5"
                                        y="37.5"
                                        style={{ fill: "#E6F0FF" }}
                                        width={407}
                                        height={437}
                                        data-original="#DEF1FF"
                                        data-old_color="#DEF1FF"
                                      />
                                      <path
                                        style={{ fill: "#3C9BEC" }}
                                        d="M305.5,391.5h-99c-7.177,0-13.804-3.846-17.365-10.077s-3.51-13.893,0.134-20.076l92.162-156.403  c2.663-4.517,4.069-9.683,4.069-14.943c0-16.266-13.234-29.5-29.5-29.5s-29.5,13.234-29.5,29.5c0,11.046-8.954,20-20,20  s-20-8.954-20-20c0-38.322,31.178-69.5,69.5-69.5s69.5,31.178,69.5,69.5c0,12.4-3.323,24.59-9.609,35.253L241.499,351.5H305.5  c11.046,0,20,8.954,20,20S316.546,391.5,305.5,391.5z"
                                        data-original="#54BBFF"
                                        data-old_color="#54BBFF"
                                      />
                                      <path
                                        d="M504.5,0H7.5C3.358,0,0,3.358,0,7.5v497c0,4.142,3.358,7.5,7.5,7.5h497c4.142,0,7.5-3.358,7.5-7.5V7.5  C512,3.358,508.642,0,504.5,0z M497,497H15V15h482V497z"
                                        data-original="#000000"
                                        style={{ fill: "#4A25AA" }}
                                        data-old_color="#000000"
                                      />
                                      <path
                                        d="M37.5,482h437c4.142,0,7.5-3.358,7.5-7.5v-437c0-4.142-3.358-7.5-7.5-7.5h-437c-4.142,0-7.5,3.358-7.5,7.5v45  c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5V45h422v422H45V112.5c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v362  C30,478.642,33.358,482,37.5,482z"
                                        data-original="#000000"
                                        style={{ fill: "#4A25AA" }}
                                        data-old_color="#000000"
                                      />
                                      <path
                                        d="M333,190c0-42.458-34.542-77-77-77s-77,34.542-77,77c0,15.164,12.336,27.5,27.5,27.5S234,205.164,234,190  c0-12.131,9.869-22,22-22c12.131,0,22,9.869,22,22c0,3.922-1.048,7.772-3.03,11.136l-92.162,156.403  c-4.998,8.481-5.068,19.059-0.184,27.605C187.507,393.691,196.656,399,206.5,399h99c15.164,0,27.5-12.336,27.5-27.5  S320.664,344,305.5,344h-50.876l67.729-114.938C329.318,217.246,333,203.738,333,190z M309.43,221.446l-74.392,126.247  c-1.367,2.319-1.386,5.192-0.05,7.529s3.82,3.779,6.512,3.779h64c6.893,0,12.5,5.607,12.5,12.5s-5.607,12.5-12.5,12.5h-99  c-4.474,0-8.633-2.414-10.853-6.298c-2.22-3.885-2.188-8.692,0.083-12.547l92.162-156.402C291.234,203.083,293,196.599,293,190  c0-20.402-16.598-37-37-37s-37,16.598-37,37c0,6.893-5.607,12.5-12.5,12.5S194,196.893,194,190c0-34.187,27.813-62,62-62  s62,27.813,62,62C318,201.061,315.037,211.935,309.43,221.446z"
                                        data-original="#000000"
                                        style={{ fill: "#4A25AA" }}
                                        data-old_color="#000000"
                                      />
                                    </g>{" "}
                                  </svg>{" "}
                                </span>
                              </div>
                              <div className="elementor-icon-box-content">
                                <h3 className="elementor-icon-box-title">
                                  <span>COURS À DISTANCE</span>
                                </h3>
                                <p className="elementor-icon-box-description">
                                  Longtemps source de scepticisme, nous
                                  confirmons aujourd'hui leur formidable
                                  efficacité. Notre solution ? Une plateforme en
                                  ligne complète pour{" "}
                                  <strong style={{ color: "#4A25AA" }}>
                                    {" "}
                                    partager des documents{" "}
                                  </strong>
                                  , expliquer les notions avec un{" "}
                                  <strong style={{ color: "#4A25AA" }}>
                                    {" "}
                                    tableau virtuel{" "}
                                  </strong>{" "}
                                  et discuter instantanément grâce à la vidéo et
                                  le chat.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-ec1c35f elementor-align-center elementor-widget elementor-widget-button"
                          data-id="ec1c35f"
                          data-element_type="widget"
                          data-widget_type="button.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-button-wrapper">
                              <a
                                href="https://www.tuteuralheure.fr/cours-en-ligne/"
                                className="elementor-button-link elementor-button elementor-size-md"
                                role="button"
                              >
                                <span className="elementor-button-content-wrapper">
                                  <span className="elementor-button-text">
                                    EN SAVOIR PLUS
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-211843d"
                    data-id="211843d"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-069f6b9 elementor-widget elementor-widget-spacer"
                          data-id="069f6b9"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-7b1ebeb elementor-view-default elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                          data-id="7b1ebeb"
                          data-element_type="widget"
                          data-widget_type="icon-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-icon-box-wrapper">
                              <div className="elementor-icon-box-icon">
                                <span className="elementor-icon elementor-animation-">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    id="Capa_1"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 512 512"
                                    style={{
                                      enableBackground: "new 0 0 512 512",
                                    }}
                                    xmlSpace="preserve"
                                    width={212}
                                    height={212}
                                  >
                                    <g>
                                      <polygon
                                        style={{ fill: "#3C9BEC" }}
                                        points="474.5,7.5 256,256 474.5,504.5 504.5,504.5 504.5,7.5 "
                                        data-original="#54BBFF"
                                        data-old_color="#54BBFF"
                                      />
                                      <rect
                                        x="7.5"
                                        y="7.5"
                                        style={{ fill: "#3C9BEC" }}
                                        width={467}
                                        height={497}
                                        data-original="#8DD2FF"
                                        data-old_color="#8DD2FF"
                                      />
                                      <polygon
                                        style={{ fill: "#E6F0FF" }}
                                        points="444.5,37.5 256,256 444.5,474.5 474.5,474.5 474.5,37.5 "
                                        data-original="#C6E8FF"
                                        data-old_color="#C6E8FF"
                                      />
                                      <rect
                                        x="37.5"
                                        y="37.5"
                                        style={{ fill: "#E6F0FF" }}
                                        width={407}
                                        height={437}
                                        data-original="#DEF1FF"
                                        data-old_color="#DEF1FF"
                                      />
                                      <path
                                        style={{ fill: "#3C9BEC" }}
                                        d="M256,391.5c-38.322,0-69.5-31.178-69.5-69.5c0-11.046,8.954-20,20-20s20,8.954,20,20  c0,16.266,13.234,29.5,29.5,29.5c16.267,0,29.5-13.234,29.5-29.5v-16.5c0-16.266-13.233-29.5-29.5-29.5c-11.046,0-20-8.954-20-20  s8.954-20,20-20c16.267,0,29.5-13.234,29.5-29.5V190c0-16.266-13.233-29.5-29.5-29.5c-16.266,0-29.5,13.234-29.5,29.5  c0,11.046-8.954,20-20,20s-20-8.954-20-20c0-38.322,31.178-69.5,69.5-69.5s69.5,31.178,69.5,69.5v16.5  c0,19.355-7.951,36.886-20.761,49.5c12.81,12.614,20.761,30.145,20.761,49.5V322C325.5,360.322,294.322,391.5,256,391.5z"
                                        data-original="#54BBFF"
                                        data-old_color="#54BBFF"
                                      />
                                      <path
                                        d="M504.5,0H7.5C3.358,0,0,3.358,0,7.5v497c0,4.142,3.358,7.5,7.5,7.5h497c4.143,0,7.5-3.358,7.5-7.5V7.5  C512,3.358,508.643,0,504.5,0z M497,497H15V15h482V497z"
                                        data-original="#000000"
                                        className="active-path"
                                        style={{ fill: "#4A25AA" }}
                                        data-old_color="#000000"
                                      />
                                      <path
                                        d="M37.5,482h437c4.143,0,7.5-3.358,7.5-7.5v-437c0-4.142-3.357-7.5-7.5-7.5h-437c-4.142,0-7.5,3.358-7.5,7.5v45  c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5V45h422v422H45V112.5c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v362  C30,478.642,33.358,482,37.5,482z"
                                        data-original="#000000"
                                        className="active-path"
                                        style={{ fill: "#4A25AA" }}
                                        data-old_color="#000000"
                                      />
                                      <path
                                        d="M256,344c-12.131,0-22-9.869-22-22c0-15.164-12.336-27.5-27.5-27.5S179,306.836,179,322c0,42.458,34.542,77,77,77  s77-34.542,77-77v-16.5c0-18.323-6.36-35.645-18.049-49.5C326.64,242.145,333,224.823,333,206.5V190c0-42.458-34.542-77-77-77  s-77,34.542-77,77c0,15.164,12.336,27.5,27.5,27.5S234,205.164,234,190c0-12.131,9.869-22,22-22c12.131,0,22,9.869,22,22v16.5  c0,12.131-9.869,22-22,22c-15.164,0-27.5,12.336-27.5,27.5s12.336,27.5,27.5,27.5c12.131,0,22,9.869,22,22V322  C278,334.131,268.131,344,256,344z M256,268.5c-6.893,0-12.5-5.607-12.5-12.5s5.607-12.5,12.5-12.5c20.402,0,37-16.598,37-37V190  c0-20.402-16.598-37-37-37s-37,16.598-37,37c0,6.893-5.607,12.5-12.5,12.5S194,196.893,194,190c0-34.187,27.813-62,62-62  s62,27.813,62,62v16.5c0,16.712-6.578,32.394-18.523,44.156c-1.432,1.41-2.237,3.335-2.237,5.344c0,2.009,0.806,3.934,2.237,5.344  C311.422,273.106,318,288.788,318,305.5V322c0,34.187-27.813,62-62,62s-62-27.813-62-62c0-6.893,5.607-12.5,12.5-12.5  S219,315.107,219,322c0,20.402,16.598,37,37,37s37-16.598,37-37v-16.5C293,285.098,276.402,268.5,256,268.5z"
                                        data-original="#000000"
                                        className="active-path"
                                        style={{ fill: "#4A25AA" }}
                                        data-old_color="#000000"
                                      />
                                    </g>{" "}
                                  </svg>{" "}
                                </span>
                              </div>
                              <div className="elementor-icon-box-content">
                                <h3 className="elementor-icon-box-title">
                                  <span>COACH D'ORIENTATION</span>
                                </h3>
                                <p className="elementor-icon-box-description">
                                  L'orientation de l'élève est une problématique
                                  pour beaucoup de familles qui ne savent pas
                                  toujours vers qui se tourner pour obtenir des
                                  conseils ciblés. Chez Tuteur à l'Heure, nous
                                  proposons aux parents les services de Karine
                                  qui conciliera coaching de motivation et
                                  accompagnement d'orientation.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-6cc03e2 elementor-align-center elementor-widget elementor-widget-button"
                          data-id="6cc03e2"
                          data-element_type="widget"
                          data-widget_type="button.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-button-wrapper">
                              <a
                                href="https://www.tuteuralheure.fr/coaching-orientation/"
                                className="elementor-button-link elementor-button elementor-size-md"
                                role="button"
                              >
                                <span className="elementor-button-content-wrapper">
                                  <span className="elementor-button-text">
                                    PROCHAINEMENT
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-6a96219 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="6a96219"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-row">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-986aa51"
                    data-id="986aa51"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-6cf4ae6 elementor-widget elementor-widget-spacer"
                          data-id="6cf4ae6"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-2d4b1ef heading-title elementor-widget elementor-widget-heading"
                          data-id="2d4b1ef"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-xl">
                              Quelques chiffres
                            </h2>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-52f66cf elementor-widget elementor-widget-spacer"
                          data-id="52f66cf"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-ta6vzba elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="ta6vzba"
              data-element_type="section"
              data-settings='{"background_background":"classic"}'
            >
              <div className="elementor-background-overlay" />
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-row">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-yy60z22"
                    data-id="yy60z22"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <section
                          className="elementor-section elementor-inner-section elementor-element elementor-element-x5vws0z elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="x5vws0z"
                          data-element_type="section"
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-zzhesi4"
                                data-id="zzhesi4"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-cqhu59t elementor-widget elementor-widget-counter"
                                      data-id="cqhu59t"
                                      data-element_type="widget"
                                      data-widget_type="counter.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-counter">
                                          <div className="elementor-counter-number-wrapper">
                                            <span className="elementor-counter-number-prefix" />
                                            <span
                                              className="elementor-counter-number"
                                              data-duration={2000}
                                              data-to-value={5290}
                                              data-from-value
                                              data-delimiter=","
                                            >
                                              6,799.5
                                            </span>
                                            <span className="elementor-counter-number-suffix" />
                                          </div>
                                          <div className="elementor-counter-title">
                                            Heures de cours
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-5k46rfd"
                                data-id="5k46rfd"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-qpxumb4 elementor-widget elementor-widget-counter"
                                      data-id="qpxumb4"
                                      data-element_type="widget"
                                      data-widget_type="counter.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-counter">
                                          <div className="elementor-counter-number-wrapper">
                                            <span className="elementor-counter-number-prefix" />
                                            <span
                                              className="elementor-counter-number"
                                              data-duration={2000}
                                              data-to-value={350}
                                              data-from-value
                                              data-delimiter=","
                                            >
                                              250
                                            </span>
                                            <span className="elementor-counter-number-suffix" />
                                          </div>
                                          <div className="elementor-counter-title">
                                            tuteurs actifs
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-81l50vb"
                                data-id="81l50vb"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-ha1omtw elementor-widget elementor-widget-counter"
                                      data-id="ha1omtw"
                                      data-element_type="widget"
                                      data-widget_type="counter.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-counter">
                                          <div className="elementor-counter-number-wrapper">
                                            <span className="elementor-counter-number-prefix" />
                                            <span
                                              className="elementor-counter-number"
                                              data-duration={2000}
                                              data-to-value="3.1"
                                              data-from-value
                                              data-delimiter=","
                                            >
                                              3.1
                                            </span>
                                            <span className="elementor-counter-number-suffix" />
                                          </div>
                                          <div className="elementor-counter-title">
                                            + Points en moyenne
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-lhc1ugy"
                                data-id="lhc1ugy"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-9ggcjm9 elementor-widget elementor-widget-counter"
                                      data-id="9ggcjm9"
                                      data-element_type="widget"
                                      data-widget_type="counter.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-counter">
                                          <div className="elementor-counter-number-wrapper">
                                            <span className="elementor-counter-number-prefix" />
                                            <span
                                              className="elementor-counter-number"
                                              data-duration={2000}
                                              data-to-value={100}
                                              data-from-value
                                              data-delimiter=","
                                            >
                                              100
                                            </span>
                                            <span className="elementor-counter-number-suffix">
                                              %
                                            </span>
                                          </div>
                                          <div className="elementor-counter-title">
                                            réussite au bac
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className=" block-group">
            <iframe
              id="AV_widget_iframe"
              title="0"
              frameBorder="0"
              width="100%"
              height="100%"
              src="//cl.avis-verifies.com/fr/cache/a/6/c/a6c2a270-6777-2464-a50f-fb57c8678718/widget4/a6c2a270-6777-2464-a50f-fb57c8678718horizontal_index.html"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="footer-zone">
        <div className="padded-content">
          <div className="left-zone">
            <img
              src={Hat}
              alt="Cours accompagnement scolaire Tuteur à l'heure"
              width={64}
              height={64}
            />
            <a href="https://tuteuralheure.fr">Tuteuralheure.fr</a>
          </div>
          <div className="icons">
            <a
              href="https://www.facebook.com/Tuteuralheure/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="auto"
                height={13}
                viewBox="0 0 220 471"
                fill="currentColor"
                className="base-icon icon-facebook"
              >
                <path d="M146.521,154.17 L146.521,113.629 C146.521,107.543 146.801,102.829 147.37,99.499 C147.937,96.164 149.227,92.884 151.229,89.646 C153.228,86.41 156.465,84.176 160.935,82.938 C165.411,81.698 171.359,81.08 178.785,81.08 L219.324,81.08 L219.324,0 L154.515,0 C117.015,0 90.082,8.897 73.712,26.691 C57.344,44.489 49.161,70.705 49.161,105.349 L49.161,154.169 L0.619,154.169 L0.619,235.255 L49.158,235.255 L49.158,470.511 L146.52,470.511 L146.52,235.256 L211.325,235.256 L219.891,154.17 L146.521,154.17 Z"></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/tuteur.a.lheure/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={64}
                height={64}
                viewBox="0 0 64 64"
                className="base-icon icon-instagram"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M32.006 17.642A14.333 14.333 0 0 0 17.652 32a14.333 14.333 0 0 0 14.354 14.358c7.946 0 14.355-6.41 14.355-14.358a14.333 14.333 0 0 0-14.355-14.358zm0 23.692c-5.134 0-9.332-4.186-9.332-9.334s4.185-9.334 9.332-9.334c5.147 0 9.333 4.186 9.333 9.334s-4.198 9.334-9.333 9.334zm18.29-24.279a3.34 3.34 0 0 1-3.348 3.349 3.34 3.34 0 0 1-3.348-3.349 3.349 3.349 0 1 1 6.696 0zm9.507 3.399c-.212-4.486-1.237-8.46-4.522-11.734-3.273-3.274-7.246-4.298-11.731-4.523-4.623-.263-18.477-.263-23.1 0-4.472.212-8.445 1.237-11.73 4.51-3.286 3.275-4.298 7.248-4.523 11.734-.263 4.624-.263 18.482 0 23.105.212 4.486 1.237 8.46 4.522 11.734 3.286 3.274 7.246 4.298 11.731 4.523 4.623.263 18.477.263 23.1 0 4.485-.212 8.458-1.237 11.73-4.523 3.274-3.274 4.298-7.248 4.523-11.734.263-4.623.263-18.469 0-23.092z"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/tuteuralheure/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={64}
                height={64}
                viewBox="0 0 64 64"
                className="base-icon icon-linkedin"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M18.192 56H7.826V22.618h10.366V56zm-5.189-37.936C9.69 18.064 7 15.318 7 12.004a6.003 6.003 0 1 1 12.007 0c0 3.314-2.69 6.06-6.004 6.06zM56.99 56H46.645V39.75c0-3.873-.078-8.84-5.39-8.84-5.389 0-6.215 4.208-6.215 8.56V56H24.685V22.618h9.942v4.553h.145c1.384-2.623 4.765-5.39 9.808-5.39C55.071 21.78 57 28.688 57 37.662V56h-.011z"
                ></path>
              </svg>
            </a>
            <a
              href="https://twitter.com/tuteuralheure_"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                width={28}
                height={24}
                viewBox="0 0 28 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="base-icon icon-twitter"
              >
                <g id="footer_twitter">
                  <path
                    id="Fill 1"
                    d="M28 2.83941C26.9701 3.32142 25.8639 3.64727 24.7009 3.79373C25.8879 3.04406 26.7972 1.85608 27.2266 0.442211C26.099 1.14856 24.865 1.6458 23.5781 1.9124C22.5309 0.735066 21.0376 0 19.3854 0C16.2141 0 13.6423 2.71148 13.6423 6.05814C13.6423 6.53234 13.6929 6.99494 13.7903 7.43911C9.01638 7.18606 4.78404 4.77423 1.9511 1.10946C1.45644 2.00451 1.1733 3.04515 1.1733 4.15547C1.1733 6.257 2.18747 8.1112 3.72849 9.19736C2.81622 9.16755 1.92399 8.90784 1.12637 8.43995V8.51649C1.12637 11.452 3.10601 13.8997 5.73386 14.4564C5.25113 14.5959 4.74451 14.6688 4.22041 14.6688C3.84987 14.6688 3.49035 14.6318 3.13915 14.5621C3.8701 16.9682 5.99142 18.7195 8.50421 18.769C6.53837 20.3943 4.06132 21.3632 1.37095 21.3632C0.907567 21.3632 0.4496 21.334 0 21.2769C2.54238 22.9963 5.56104 24 8.80494 24C19.3698 24 25.1477 14.7687 25.1477 6.76325C25.1477 6.50043 25.1422 6.23957 25.1302 5.97964C26.2559 5.12085 27.2277 4.05752 28 2.83941Z"
                    fill="currentColor"
                  />
                </g>
              </svg>
            </a>
            <a
              href="https://www.youtube.com/channel/UCz-9FmBxIdN-jejQ5qfJngQ"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={64}
                height={64}
                viewBox="0 0 64 64"
                className="base-icon icon-youtube"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M58.83 18.259c-.644-2.464-2.542-4.404-4.951-5.063C49.512 12 32 12 32 12s-17.512 0-21.879 1.196c-2.41.659-4.307 2.6-4.95 5.063C4 22.724 4 32.04 4 32.04s0 9.317 1.17 13.782c.644 2.464 2.542 4.323 4.951 4.982C14.488 52 32 52 32 52s17.512 0 21.879-1.196c2.41-.659 4.307-2.518 4.95-4.982C60 41.357 60 32.04 60 32.04s0-9.316-1.17-13.781zm-32.557 22.24V23.582l14.636 8.459-14.636 8.458z"
                ></path>
              </svg>
            </a>
          </div>
          <div className="hidden-lg hidden-xs">{/**/}</div>
        </div>
      </div>
      <div className="fixed-footer-mobile hidden-lg">
        <div className="fixed-footer-mobile-buttons">
          <div className="back-button disabled">
            <svg
              width={64}
              height={64}
              viewBox="0 0 64 64"
              xmlns="http://www.w3.org/2000/svg"
              className="base-icon icon-arrow-left"
              style={{ marginRight: "10px" }}
            >
              <path
                fill="currentColor"
                d="M31.97 8L8 31.9l.1.099-.1.1L31.97 56l4.23-4.218-16.848-16.8H56v-5.964l-36.648-.001 16.849-16.8z"
                fillRule="evenodd"
              />
            </svg>
          </div>
          <div className="progress-bar background-bar">
            <div
              className="foreground-bar"
              style={{ width: "14.285714285714285%" }}
            />
          </div>
          <div className="forward-button" style={{ visibility: "hidden" }} />
        </div>
      </div>
      <div title="Revenir au formulaire" className="back-to-top hidden-xs">
        <svg
          width={64}
          height={64}
          viewBox="0 0 64 64"
          xmlns="http://www.w3.org/2000/svg"
          className="base-icon icon-arrow-top"
        >
          <path
            fill="currentColor"
            d="M8 31.97L31.9 8l.099.1.1-.1L56 31.97l-4.218 4.23-16.8-16.848V56h-5.964l-.001-36.648-16.8 16.849z"
            fillRule="evenodd"
          />
        </svg>
      </div>
    </div>
  </>
)

export default IndexPage

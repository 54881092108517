import React from "react"

import stepType from "../assets/static/step1.png"
import stepLevel from "../assets/static/step2.png"
import stepPostal from "../assets/static/step3.png"
import stepInfo from "../assets/static/step4.png"

class StepType extends React.Component {
  render() {
    return (
      <>
        <div className="form-generator-field field-html-wrapper">
          <div>
            <div>
              <p style={{ textAlign: "center" }}>
                <img
                  src={stepType}
                  alt="Etape 1 du formulaire"
                  width={272}
                  height={32}
                />
              </p>
              <h3
                style={{
                  textAlign: "center",
                  color: "var(--AkMajorBlue)",
                  fontWeight: "bold",
                }}
              ></h3>
            </div>
          </div>
          {/**/}
        </div>
        <div className="form-generator-field field-radio-wrapper item-large-lg columns-1 field-semi-wide  ">
          <div className="field-radio base-radio">
            <label>
              <input
                type="radio"
                name="type"
                defaultValue="Un parent"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />
              <div className="label-text" style={{ color: "white" }}>
                Je suis un parent à la recherche de cours particuliers
              </div>
            </label>
            <label>
              <input
                type="radio"
                name="type"
                defaultValue="Un élève"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />
              <div className="label-text" style={{ color: "white" }}>
                Je suis un élève qui souhaite du renfort
              </div>
            </label>
            {/**/}
          </div>
          {/**/}
        </div>
      </>
    )
  }
}

class StepLevel extends React.Component {
  render() {
    return (
      <>
        <div className="form-generator-field field-html-wrapper">
          <div>
            <div>
              <p style={{ textAlign: "center" }}>
                <img
                  src={stepLevel}
                  alt="Etape 1 du formulaire"
                  width={272}
                  height={32}
                />
              </p>
              <h3
                style={{
                  textAlign: "center",
                  color: "var(--AkMajorBlue)",
                  fontWeight: "bold",
                }}
              >
                Sélectionnez le niveau des cours&nbsp;:
              </h3>
            </div>
          </div>
          {/**/}
        </div>
        <div className="form-generator-field field-radio-wrapper columns-1 field-semi-wide  ">
          <div className="field-radio base-radio">
            <label>
              <input
                type="radio"
                name="level-a"
                defaultValue="Primaire"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Primaire</div>
            </label>
            <label>
              <input
                type="radio"
                name="level-a"
                defaultValue="Collège"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Collège</div>
            </label>
            <label>
              <input
                type="radio"
                name="level-a"
                defaultValue="Lycée"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Lycée</div>
            </label>
            <label>
              <input
                type="radio"
                name="level-a"
                defaultValue="Supérieur"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Supérieur</div>
            </label>{" "}
            {/**/}
          </div>{" "}
          {/**/}
        </div>
      </>
    )
  }
}

class StepMatiere extends React.Component {
  render() {
    return (
      <>
        <div className="form-generator-field field-html-wrapper">
          <div>
            <div>
              <p style={{ textAlign: "center" }}>
                <img
                  src={stepPostal}
                  alt="Etape 1 du formulaire"
                  width={272}
                  height={32}
                />
              </p>
              <h3
                style={{
                  textAlign: "center",
                  color: "var(--AkMajorBlue)",
                  fontWeight: "bold",
                }}
              >
                Cliquez sur la matière principale à travailler&nbsp;:
              </h3>
            </div>
          </div>
          {/**/}
        </div>
        <div className="form-generator-field field-radio-wrapper columns-2 field-semi-wide">
          <div className="field-radio base-radio">
            <label>
              <input
                type="radio"
                name="subject-b"
                defaultValue="Mathématiques"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Mathématiques</div>
            </label>
            <label>
              <input
                type="radio"
                name="subject-b"
                defaultValue="Francais"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Français</div>
            </label>
            <label>
              <input
                type="radio"
                name="subject-b"
                defaultValue="Anglais"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Anglais</div>
            </label>
            <label>
              <input
                type="radio"
                name="subject-b"
                defaultValue="Physique-Chimie"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Physique-Chimie</div>
            </label>
            <label>
              <input
                type="radio"
                name="subject-b"
                defaultValue="Histoire-Geographie"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Histoire-Géographie</div>
            </label>
            <label>
              <input
                type="radio"
                name="subject-b"
                defaultValue="SVT"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">SVT</div>
            </label>
            <label>
              <input
                type="radio"
                name="subject-b"
                defaultValue="Espagnol"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Espagnol</div>
            </label>
            <label>
              <input
                type="radio"
                name="subject-b"
                defaultValue="Philosophie"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Philosophie</div>
            </label>
            <label>
              <input
                type="radio"
                name="subject-b"
                defaultValue="Soutien général"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Soutien général</div>
            </label>
            <label>
              <input
                type="radio"
                name="subject-b"
                defaultValue="Autres"
                onClick={this.props.next}
                onChange={e => this.props.handleInputChange(e)}
              />{" "}
              <div className="label-text">Autres</div>
            </label>{" "}
            {/**/}
          </div>{" "}
          {/**/}
        </div>
      </>
    )
  }
}

class StepInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clickedMadame: false,
      clickedMonsieur: false,
    }
  }

  handleClickMadame = () => {
    this.setState({
      clickedMadame: true,
      clickedMonsieur: false,
    })
  }

  handleClickMonsieur = () => {
    this.setState({
      clickedMadame: false,
      clickedMonsieur: true,
    })
  }

  render() {
    return (
      <>
        <div className="form-generator-field field-html-wrapper">
          <div>
            <div>
              <p style={{ textAlign: "center" }}>
                <img
                  src={stepInfo}
                  alt="Etape 1 du formulaire"
                  width={272}
                  height={32}
                />
              </p>
              <h3
                style={{
                  textAlign: "center",
                  color: "var(--AkMajorBlue)",
                  fontWeight: "bold",
                }}
              >
                Enfin, veuillez indiquer vos coordonnées&nbsp;:
              </h3>
            </div>
          </div>
        </div>
        <div className="form-generator-field field-radio-wrapper item-small">
          <div className="field-radio base-radio">
            <label
              onClick={this.handleClickMadame}
              className={this.state.clickedMadame ? "is-checked" : null}
            >
              <input
                type="radio"
                name="profileType"
                defaultValue="Madame"
                onChange={e => this.props.handleInputChange(e)}
              />
              <div className="label-text">Madame</div>
            </label>
            <label
              onClick={this.handleClickMonsieur}
              className={this.state.clickedMonsieur ? "is-checked" : null}
            >
              <input
                type="radio"
                name="profileType"
                defaultValue="Monsieur"
                onChange={e => this.props.handleInputChange(e)}
              />
              <div className="label-text">Monsieur</div>
            </label>
          </div>
        </div>
        <div className="form-generator-field field-input-wrapper">
          <div className="field-input base-input field-input-text focused">
            <label htmlFor="input0.23383229187677923">
              <span className="label-text">Prénom</span>
            </label>{" "}
            <div className="input-wrapper">
              <input
                spellCheck="false"
                autoComplete="new-password"
                type="text"
                id="input0.23383229187677923"
                name="firstName"
                placeholder="Saisissez votre prénom"
                onChange={e => this.props.handleInputChange(e)}
                required
              />
            </div>{" "}
            {/**/}
          </div>{" "}
          {/**/}
        </div>
        <div className="form-generator-field field-input-wrapper">
          <div className="field-input base-input field-input-text focused">
            <label htmlFor="input0.23383229187677923">
              <span className="label-text">Nom</span>
            </label>{" "}
            <div className="input-wrapper">
              <input
                spellCheck="false"
                autoComplete="new-password"
                type="text"
                id="input0.23383229187677923"
                name="lastName"
                placeholder="Saisissez votre nom"
                onChange={e => this.props.handleInputChange(e)}
                required
              />
            </div>{" "}
            {/**/}
          </div>{" "}
          {/**/}
        </div>
        <div className="form-generator-field field-input-wrapper">
          <div className="field-input base-input field-input-email focused">
            <label htmlFor="input0.5286623148850285">
              <span className="label-text">E-mail</span>
            </label>{" "}
            <div className="input-wrapper">
              <input
                spellCheck="false"
                autoComplete="on"
                type="email"
                id="input0.5286623148850285"
                name="email"
                placeholder="Saisissez votre adresse email"
                onChange={e => this.props.handleInputChange(e)}
                required
              />
            </div>{" "}
            {/**/}
          </div>{" "}
          {/**/}
        </div>
        <div className="form-generator-field field-phone-number-wrapper">
          <div className="field-phone-number base-input focused">
            <label htmlFor="phone-number0.18234676967425578">
              <span className="label-text">Téléphone</span>
            </label>{" "}
            <div className="input-wrapper">
              <input
                type="tel"
                autoComplete="on"
                id="phone-number0.18234676967425578"
                maxLength={25}
                name="telephone"
                placeholder="Saisissez votre numéro de téléphone"
                tabIndex={0}
                className="vti__input"
                onChange={e => this.props.handleInputChange(e)}
                required
              />
            </div>{" "}
            {/**/}
          </div>{" "}
          {/**/}
        </div>
        <div className="form-generator-field field-input-wrapper">
          <div className="field-input base-input field-input-text focused">
            <label htmlFor="input0.23383229187677923">
              <span className="label-text">Code postal</span>
            </label>{" "}
            <div className="input-wrapper">
              <input
                spellCheck="false"
                autoComplete="new-password"
                type="text"
                id="input0.23383229187677923"
                name="postal"
                onChange={e => this.props.handleInputChange(e)}
                required
                placeholder="Saisissez votre code postal"
              />
            </div>{" "}
            {/**/}
          </div>{" "}
          {/**/}
        </div>
        <div className="form-generator-field field-html-wrapper   ">
          <div>
            <div>
              En soumettant ce formulaire, j'accepte{" "}
              <a
                href="https://www.tuteuralheure.fr/cgv/"
                target="_blank"
                rel="noreferrer"
              >
                la politique de protection des données de Tuteur à l'Heure.
              </a>
              .
            </div>
          </div>{" "}
          {/**/}
        </div>
      </>
    )
  }
}

const Step = ({ step, next, handleInputChange }) => {
  switch (step) {
    case 1:
      return <StepType next={next} handleInputChange={handleInputChange} />
    case 2:
      return <StepLevel next={next} handleInputChange={handleInputChange} />
    case 3:
      return <StepMatiere next={next} handleInputChange={handleInputChange} />
    case 4:
      return <StepInfo handleInputChange={handleInputChange} />
    default:
      return (
        <div>
          Merci pour votre confiance ! L'équipe de Tuteur à l'Heure revient vers
          vous au plus vite.
        </div>
      )
  }
}

export default Step

import React from "react"

import CurrentStep from "./step"

const ZapierURL = "https://hooks.zapier.com/hooks/catch/6841944/o0ypdrj/"

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 1,
      postal: "",
    }
  }

  componentDidMount() {
    const source = new URLSearchParams(document.location.search).get("source")
    this.setState({
      source: source,
    })
  }

  next = () => {
    this.setState({
      step: this.state.step + 1,
    })
  }

  previous = () => {
    this.setState({
      step: this.state.step - 1,
    })
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  postFormToZapier = () => {
    const body = this.state
    delete body.step
    fetch(ZapierURL, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    }).then(
      () => (document.location = document.location.origin + `/step?email=${this.state.email}/`)
    )
  }

  render() {
    return (
      <div className="primary-zone">
        <div className="primary-zone-content">
          <div className="primary-header hidden-xs">
            <div className=" hidden-xs">
              <div className="base-block-type-wysiwyg">
                <p></p>
                <div className="contact-us">
                  <svg
                    width={64}
                    height={64}
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                    className="base-icon icon-call"
                  >
                    <path
                      d="M41.466 38.233c1.306-.69 2.685-.844 3.654-.253.862.527 8.94 5.888 9.819 6.503 1.261.884 1.44 2.91.309 4.523-2.208 3.16-5.943 7.054-7.903 6.993-.96-.03-1.617-.092-2.639-.304-1.48-.308-3.117-.859-4.947-1.719-4.82-2.263-10.42-6.392-16.883-12.857-6.462-6.463-10.59-12.063-12.853-16.883-.86-1.83-1.41-3.469-1.718-4.949a13.62 13.62 0 01-.304-2.64c-.063-1.959 3.824-5.692 6.991-7.906 1.607-1.12 3.626-.93 4.516.3.495.686 5.783 8.688 6.499 9.818.612.964.454 2.348-.243 3.667-.139.266-.967 1.74-2.484 4.425.48 2.033 2.387 4.716 5.72 8.049 3.333 3.333 6.015 5.239 8.044 5.716a426.481 426.481 0 014.422-2.483z"
                      fill="currentColor"
                      fillRule="evenodd"
                    />
                    componentDidUpdate
                  </svg>
                  <div>
                    <div className="number">
                      <a href="tel:07 80 91 91 20">07 80 91 91 20</a>
                    </div>
                    <div className="label">Disponibles 7j/7j de 10h à 18h</div>
                  </div>
                </div>
                <p />
              </div>
            </div>
          </div>
          <div className="primary-body" style={{ backgroundColor: "#F1F1F1" }}>
            <form className="form-generator">
              <div className="form-generator-field field-html-wrapper field-wide  ">
                <div>
                  <div style={{fontSize: '20px', marginLeft: '30px', marginRight: '30px'}}>
                    Bénéficiez d’informations sur nos cours particuliers
                    (précisez vos besoins ci-dessous).
                  </div>
                </div>
                {/**/}
              </div>
              <CurrentStep
                step={this.state.step}
                next={this.next}
                handleInputChange={this.handleInputChange}
              />
            </form>
            <div className="form-controls-wrapper theme-turquoise">
            {this.state.step === 4 && (
                <div
                  role="button"
                  className="base-button base-button-solid"
                  style={{ opacity: 100 }}
                  onClick={
                    this.state.profileType &&
                    this.state.telephone &&
                    this.state.postal &&
                    this.state.lastName &&
                    this.state.firstName &&
                    this.state.email &&
                    this.postFormToZapier
                  }
                >
                  Demande d'informations
                </div>
              )}
            </div>
            <div className="form-controls-wrapper theme-turquoise">
              {this.state.step !== 1 && this.state.step < 5 && (
                <div
                  role="button"
                  className="base-button base-button-solid"
                  style={{ opacity: 100 }}
                  onClick={this.previous}
                >
                  <span>Précédent</span>
                </div>
              )}
            </div>
          </div>
          <div className="primary-footer hidden-xs">
            <div className="progress-bar background-bar">
              <div
                className="foreground-bar"
                style={{ width: "14.285714285714285%" }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Form
